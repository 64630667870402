import { IConfig } from "./interfaces";
export const appVersion = process.env.appVersion;
export const defaultConfig = {
    autoShowTime: -1,
    height: 618,
    newTabForMobile: false,
    //title: "Matic Widget",
    width: 540,
    position: '',
    //type: 'wallet',
    //shouldfloat: true,
    appName: '',
    relativePath: '',
    assetsFromWidgetServer: true,
    overlay: false,
    style: {},
    closable: true,
    loading: {
        color: '#3e3e3e',
        background: '#f0f0f0',
        'font-size': '24px',
        'line-height': '28px',
    },
} as IConfig;
