export enum WIDGET_EVENT {
    close = "close",
    burnInit = "burnInit",
    burnComplete = "burnComplete",
    exitInit = "exitInit",
    exitError = "exitError",
    exitComplete = "exitComplete",
    confirmWithdrawInit = "confirmWithdrawInit",
    confirmWithdrawComplete = "confirmWithdrawComplete",
    confirmWithdrawError = "confirmWithdrawError",
    load = "load",
    approveInit = "approveInit",
    approveComplete = "approveComplete",
    approveError = "approveError",
    depositInit = "depositInit",
    depositComplete = "depositComplete",
    depositError = "depositError",
}
