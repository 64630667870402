export class EventBus {
    private _events: { [eventName: string]: Function[] } = {};

    on(eventName: string, callback: Function) {

        if (!this._events[eventName]) {
            this._events[eventName] = [];
        }
        this._events[eventName].push(callback);
    }

    off(eventName, callback: Function) {
        if (callback) {
            const index = this._events[eventName].findIndex(q => q === callback);
            if (index >= 0) {
                this._events[eventName].splice(index, 1);
            }
        }
        else {
            this._events[eventName] = null as any;
        }
    }

    emit(eventName, ...args) {
        const events = this._events[eventName]
        if (events) {
            events.forEach(ev => {
                ev(args);
            });
        }
    }
}
