export function addAnimation(element, animationName) {
    const node : HTMLElement = typeof element == "string" ? document.querySelector(element) : element;

    return new Promise<void>(res => {
        function handleAnimationEnd() {
            node.classList.remove(animationName);
            node.removeEventListener('transitionend', handleAnimationEnd)
            res()
        }
        node.classList.add(animationName);
        node.addEventListener('transitionend', handleAnimationEnd);
    })

}